.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

@font-face {
  font-family: "ValkyRegular";
  src: Local("ValkyRegular"),
    url("./fonts/ValkyRegular.ttf") format("truetype");
}